"use strict";

// Import both common forms of the jQuery static object.
import $                    from "jquery";
import jQuery               from "jquery";
import "jquery-match-height";

// Application imports.
import Application          from "./main/Application";
import initialiseJRumble    from "./vendor/jackrugile/jrumble/jquery.jrumble.1.3";
import initDebounce         from "./vendor/verge/jquery.debounce";

// Configure jQuery.
initialiseJRumble( $ );
initialiseJRumble( jQuery );
initDebounce( $ );

// Intialise the frontend application.
new Application();

jQuery(document).ready(function($) {
    $(".toggleMenu").click(function() {
        
        $("#mobile-menu").toggle();
    })

    $(".mobile-nav li.has_children > div").on("click", function () {
        event.preventDefault();
        $(".mobile-nav li > ul").addClass("hidden");
        $(this).siblings("ul").toggleClass("hidden");
      });
})